export const SPLIT_PANEL_I18NSTRINGS = {
    preferencesTitle: 'Split panel preferences',
    preferencesPositionLabel: 'Split panel position',
    preferencesPositionDescription: 'Choose the default split panel position for the service.',
    preferencesPositionSide: 'Side',
    preferencesPositionBottom: 'Bottom',
    preferencesConfirm: 'Confirm',
    preferencesCancel: 'Cancel',
    closeButtonAriaLabel: 'Close panel',
    openButtonAriaLabel: 'Open panel',
    resizeHandleAriaLabel: 'Resize split panel',
};
export const CONSTANTS = {
    WIKI_LINK: 'https://w.amazon.com/bin/view/AccSys/inner/FAR/DeviceRenaming/',
    GUARDIAN_HUB: 'https://guardian.gso.amazon.dev/',
    RIVER_LINK: 'https://river.amazon.com/?org=AccSys&q0=f0aa3f34-9eed-4c05-bbb5-2a39faa0e543',
};
